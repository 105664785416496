
// on load of venue form loads sector and related sector category
$(document).on('turbolinks:load', function () {
var sector_categories, sector, options;
sector_categories = $('#casino_sector_categories_select').html();
  if (($('#casino_sector_categories_select').length > 0) && $('#casino_sector_select :selected').val()) {
    sector = $('#casino_sector_select :selected').text();
    sector_category = $('#casino_sector_categories_select :selected').text();
    if (sector == "BINGO" && sector_category == "Traditional") {
      $('#is_hybrid').show();
    } else {
      $('#is_hybrid').find('.hybrid-checkbox').prop('checked', false);
      $('#is_hybrid').hide();
    }
    options = $(sector_categories).filter("optgroup[label=" + sector + "]").html();
    if (options) {
      return $('#casino_sector_categories_select').html(options);
    } else {
      return $('#casino_sector_categories_select').empty();
      $('#casino_sector_categories_select').parent().hide();
    }
  }
  $('#casino_sector_select').change(function() {
    sector = $('#casino_sector_select :selected').text();
    options = $(sector_categories).filter("optgroup[label=" + sector + "]").html();
    if (sector) {
      $('#is_hybrid').find('.hybrid-checkbox').prop('checked', false);
      $('#is_hybrid').hide();
    }
    if (options) {
      return $('#casino_sector_categories_select').html(options);
    } else {
      return $('#casino_sector_categories_select').empty();
      $('#casino_sector_categories_select').parent().hide();
    }
  });

  // on change of venue sector loads related sector category
  //on change of venue sector categories(Traditional) hide/show hybrid checkbox
  $('#casino_sector_categories_select').change(function() {
    sector_category = $('#casino_sector_categories_select :selected').text();
    if (sector_category == "Traditional") {
      $('#is_hybrid').show();
    } else {
      $('#is_hybrid').find('.hybrid-checkbox').prop('checked', false);
      $('#is_hybrid').hide();
    }
  });
});


function selectAll() {
  $('#select_all').change(function() {
    if($(this).is(":checked")) {
      $('#select_all_input').val(true);
      $('.select-all').prop('checked', true);
    }
    else
    {
      $('#select_all_input').val(false);
     $('.select-all').prop('checked', false);
    }
  });
}

$(document).on('turbolinks:load', function () {
	$("#venue_company_id").on("change", function(){
    updateSelectFields();
  });

	function updateSelectFields() {
    companyId = $('#venue_company').val();

    if(companyId == "") {
      companyId = "All";
    }
    $.ajax({
      url: "/admins/venues/get_filtered_data",
      type: 'get',
      data: {
        company_id: companyId
      },
      success: function(res) {
        $('.area-list option').remove();
        $('.cluster-list option').remove();
        $('.region-list option').remove();
        var areaOptions="";
        var regionOptions= "";
        var clusterOptions= "";
        
        for(var i = 0; i < res["areas"].length; i++) {
          areaOptions += "<option value='" + res["areas"][i].value + "'>" + res["areas"][i].label + "</option>";
        }
        for(var i = 0; i < res["regions"].length; i++) {
          regionOptions += "<option value='" + res["regions"][i].value + "'>" + res["regions"][i].label + "</option>";
        }
        for(var i = 0; i < res["clusters"].length; i++) {
          clusterOptions += "<option value='" + res["clusters"][i].value + "'>" + res["clusters"][i].label + "</option>";
        }
        $( "#venue_region_id" ).append(regionOptions);
        $( "#venue_area_id" ).append(areaOptions);
        $( "#venue_cluster_id" ).append(clusterOptions);
      }
    });
  }
});